/* Loader.css */
.loader,
.absoluteLoader{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height */
}

.absoluteLoader {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  z-index: 9999;

  &::before {
    content: "";
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;

  }
  
}


.spinner {
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid #ff8fb0;
  /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}