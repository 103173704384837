$port-gore: #292460;
$gallery: #f0f0f0;
$white: #fff;
$riptide: #7ee0d2;
$gray: #7f7f7f;
$athens-gray: #e6eaee;
$purple-heart: #7226e0;

$chat-height: 380px;
$chat-max-width: 420px;
$chat-distance-to-window: 80px;
$chat-padding: 20px;
$header-height: 54px;
$entry-height: 60px;
$pink: #ff8fb0;

#chat-circle {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background: #ff8fb0;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  color: white;
  padding: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.6),
    0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 22;
}

#chat-circle i {
  font-size: 35px;
  color: #fff;
}

.btn#my-btn {
  background: white;
  padding-top: 13px;
  padding-bottom: 12px;
  border-radius: 45px;
  padding-right: 40px;
  padding-left: 40px;
  color: #5865c3;
}

#chat-overlay {
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: none;
}

.chatbot {
  position: fixed;
  top: 0;
  bottom: 5px;
  width: 100%;
  box-shadow: 0 -6px 99px -17px rgba(0, 0, 0, 0.68);
  z-index: 22;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media screen and (min-width: 640px) {

    max-width: $chat-max-width;
    right: 30px;
    top: auto;
  }

  &.chatbot--closed {
    top: auto;
    width: 100%;
    display: none;
  }
}

.chatbot__header {
  color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $pink;
  height: 60px;
  padding: 0 $chat-padding;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  &:hover {
    background-color: #000;
  }

  p {
    margin-right: $chat-padding;
  }

  .chatbot--closed & {
    display: none;
  }
}

.chatbot__close-button {
  fill: $white;

  &.icon-speech {
    width: 20px;
    display: none;

    .chatbot--closed & {
      display: block;
    }
  }

  &.icon-close {
    width: 14px;

    .chatbot--closed & {
      display: none;
    }
  }
}

.chatbot__message-window {
  height: calc(100% - (#{$header-height} + #{$entry-height}));
  padding: ($chat-padding * 2) $chat-padding $chat-padding;
  background-color: $white;
  overflow-x: none;
  overflow-y: auto;


  @media screen and (min-width: 640px) {
    height: $chat-height;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .chatbot--closed & {
    display: none;
  }
}

.chatbot__messages {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: auto;

  li {
    margin-bottom: $chat-padding;

    &.is-ai {
      display: inline-flex;
      align-items: flex-start;
    }

    &.is-user {
      text-align: right;
      display: inline-flex;
      align-self: flex-end;
    }

    &.is-dev {
      text-align: right;
      display: inline-flex;
      align-self: center;
    }

    &.chatEnd {
      display: inline-flex;
      align-self: center;
    }

    .is-ai__profile-picture {
      margin-right: 8px;

      .icon-avatar {
        width: 40px;
        height: 40px;
        background: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        i {
          color: #fff;
        }
      }
    }
  }
}

.chatbot__message {
  display: inline-block;
  padding: 12px $chat-padding;
  word-break: break-word;
  margin: 0;
  border-radius: 6px;
  letter-spacing: -0.01em;
  line-height: 1.45;
  overflow: hidden;
  margin-bottom: 1rem;

  .is-ai & {
    background-color: $gallery;
    margin-right: $chat-padding * 1.5;
    font-weight: 600;
  }

  .is-user & {
    background-color: $riptide;
    margin-left: $chat-padding * 1.5;
  }

  a {
    color: $purple-heart;
    word-break: break-all;
    display: inline-block;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  button {
    background-color: $white;
    font-weight: 300;
    border: 2px solid $purple-heart;
    border-radius: 50px;
    padding: 8px 20px;
    margin: -8px 10px 18px 0;
    transition: background-color 0.2s ease;
    cursor: pointer;

    &:hover {
      background-color: darken($white, 05%);
    }

    &:focus {
      outline: none;
    }
  }

  img {
    max-width: 100%;
  }

  .card {
    background-color: $white;
    text-decoration: none;
    overflow: hidden;
    border-radius: 6px;
    color: black;
    word-break: normal;

    .card-content {
      padding: $chat-padding;
    }

    .card-title {
      margin-top: 0;
    }

    .card-button {
      color: $purple-heart;
      text-decoration: underline;
    }
  }
}

.animation {
  &:last-child {
    animation: fadein 0.65s;
    animation-timing-function: all 200ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}

.chatbot__arrow {
  width: 0;
  height: 0;
  margin-top: 18px;
}

.chatbot__arrow--right {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid $riptide;
}

.chatbot__arrow--left {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid $gallery;
}

.chatbot__entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $entry-height;
  padding: 0 $chat-padding;
  border-top: 1px solid $athens-gray;
  background-color: $pink;

  .chatbot--closed & {
    display: none;
  }
}

.chatbot__input {
  height: 100%;
  width: 80%;
  border: 0;

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: $gray;
  }

  &::-moz-placeholder {
    color: $gray;
  }

  &::-ms-input-placeholder {
    color: $gray;
  }

  &::-moz-placeholder {
    color: $gray;
  }
}

.chatbot__submit {
  fill: $purple-heart;
  height: 22px;
  width: 22px;
  transition: fill 0.2s ease;
  cursor: pointer;

  &:hover {
    fill: darken($purple-heart, 20%);
  }
}

.u-text-highlight {
  color: $white;
}

//Animated Loader
//----------------
.loader {
  margin-bottom: -2px;
  text-align: center;
  opacity: 0.3;
}

.loader__dot {
  display: inline-block;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  margin: 0 1px;
  background: black;
  border-radius: 50px;
  animation: loader 0.45s infinite alternate;

  &:nth-of-type(2) {
    animation-delay: 0.15s;
  }

  &:nth-of-type(3) {
    animation-delay: 0.35s;
  }
}

// KeyFrames
@keyframes loader {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-5px);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    margin-top: 10px;
    margin-bottom: 0;
  }

  to {
    opacity: 1;
    margin-top: 0;
    margin-bottom: 10px;
  }
}

input {
  font-family: "Open Sans", sans-serif;
}

strong {
  font-weight: 600;
}

.intro {
  display: block;
  margin-bottom: 20px;
}

.options {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
  margin-right: 30px;
}
@media (max-width: 992px) {  // 991.98px ensures it doesn't overlap with lg itself
  .options {
    margin-right: 0px;
    font-size: 14px;
  }
}
.options .option {
  // flex: 1 1 auto;
  margin-bottom: 0px;
}

.options .option a {
  background-color: #ff8fb0;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
}

.loading-msg {
  margin-left: 54px;
}

.click-here {
  color: rgb(27, 27, 221);
  text-decoration: underline;
  cursor: pointer;
}

.pending-chat-count {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(0, 0, 0);
  color: white;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;

}

.clearChat {
  .btn:hover {
    color: #000 !important;
  }
}


@media (max-width: 991px) {
  #chat-circle {
    bottom: 20px;
    right: 20px;
}
  .chatbot {
    top: 110px;
    // margin: 0 auto;
    // height: 450px;
    // left: 20px;
    // width: 93%;


    &.chatbot--closed {
      top: auto;
      width: 100%;
      display: none;
    }
  }
}