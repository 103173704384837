// =====Styles======

@import "~bootstrap/dist/css/bootstrap.min.css";

:root {
  --color-pink: #ff8fb0;
  --color-black: #000;
  --color-white: #fff;
  --color-grey: #f3f3f3;
}

// ---Commom--

html,
body {
  height: 100%;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch; 
  background: white !important;
}

img {
  width: 100%;
}

body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth !important;
  box-sizing: border-box;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "gilroy-font-light";
  font-weight: 600 !important;
}

ul {
  list-style: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

p {
  font-family: "gilroy-font-light";
  font-size: 1rem;
}

h2 {
  font-size: 2rem;
  font-weight: 600 !important;
  margin-bottom: 15px !important;
}

a {
  font-family: "gilroy-font-light";
  font-weight: 600;
  text-decoration: none !important;
  color: #2e3842;
  cursor: pointer !important;
}

span {
  font-family: "gilroy-font-light";
  font-weight: 600;
}

.bg_primary {
  background-color: var(--color-pink) !important;
  color: #fff !important;
  font-weight: 900 !important;
}

section {
  padding: 3rem 0;
  position: relative;

  @media(min-width: 576px) {
    padding: 3rem 0;
  }
}

.page-wrap {
  padding-top: 68px;
  position: relative;
}

.form-control:focus {
  border-color: #ff8fb0;
  box-shadow: unset !important;
}

.accordion-button {
  background-color: unset !important;
  border-bottom: unset !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "gilroy-font-light" !important;
}

// ---font-family---

@font-face {
  font-family: "pontiac";
  src: url("./font-family/Demo_Fonts/Fontspring-DEMO-pontiac_black.otf") format("opentype"),
    url("./font-family/Demo_Fonts/Fontspring-DEMO-pontiac_bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gilroy-font";
  src: url("./font-family/Gilroy-Font/Radomir\ Tinkov\ -\ Gilroy-ExtraBold.otf") format("opentype"),
    url("./font-family/Gilroy-Font/Radomir\ Tinkov\ -\ Gilroy-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gilroy-font-light";
  src: url("./font-family/Gilroy-Font/Radomir\ Tinkov\ -\ Gilroy-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy-fonts';
  src: url(./font-family/Gilroy-Font/Gilroy-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy-fonts';
  src: url(./font-family/Gilroy-Font/Gilroy-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy-fonts';
  src: url(./font-family/Gilroy-Font/Gilroy-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy-fonts';
  src: url(./font-family/Gilroy-Font/Gilroy-ExtraBold.ttf);
  font-weight: 800;
  /* Extra bold weight */
  font-style: normal;
}

@font-face {
  font-family: 'gilroy-fonts';
  src: url(./font-family/Gilroy-Font/Gilroy-ThinItalic.ttf);
  font-weight: normal;
  font-style: italic;
  /* Italic style */
}

@font-face {
  font-family: 'gilroy-fonts';
  src: url(./font-family/Gilroy-Font/Gilroy-SemiBold.ttf);
  font-weight: 700;
  font-style: italic;
  /* Italic style */
}


.theme-outline-sm {
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid var(--color-pink);
  color: #454545;
  background-color: #fff;
  text-align: center;
  font-weight: 600;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  background-color: #fff;

  &:hover {
    background: #ff6bb5;
    color: #fff;
  }

  &.active {
    background: #ff6bb5;
    color: #fff;
  }
}

.theme-outline-btn {
  border: 1px solid var(--color-pink);
  border-radius: 40px;
  font-size: 16px;
  padding: 10px 30px;
  color: #8d8d8d;
  background-color: #fff;
  text-align: center;
  font-weight: 600;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  background-color: #fff;

  &:hover {
    background: #ff6bb5;
    color: #fff;
  }

  &.active {
    background: #ff6bb5;
    color: #fff;
  }
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
}


.theme_btn {
  background-color: #ff8fb0;
  border-radius: 30px;
  font-size: 15px;
  padding: 10px 20px;
  color: #fff;
  transition: 0.3s ease-in-out;
  text-align: center;
  cursor: pointer;
  font-family: 'gilroy-fonts', sans-serif;
  font-weight: 600;
  -webkit-appearance: none;
  border: none;
  outline: none;
  -webkit-border-radius: 30px;
  -webkit-background-clip: padding-box;

  @media (min-width: 768px) {
    padding: 10px 30px;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 500;

  }

  &.active {
    background: #ff6bb5;
  }

  &:hover {
    background: #ff6bb5;
  }
}




.btn-white {
  background-color: var(--color-white);
  padding: 12px 50px;
  color: #ff6bb5;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  cursor: pointer;
}

button {
  background: transparent;
  border: 0;
}

::placeholder {
  font-size: 15px;
  color: var(--color-grey);
}

// ---Common--end---

.half {
  @media (min-width: 768px) {
    flex: 0 1 calc(50% - 1.75rem);
  }
}

// .hero-container {
//   padding: 3rem 0;
// }



.main_page {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 3rem;
  padding-top: 3rem;

  @media(min-width: 768px) {
    height: 100vh;
    padding-top: 0;
  }
}

.hero__search {
  width: 100%;
}


.hero {
  display: flex;
  flex-direction: column;

  &__photo {

    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;

    @media (min-width: 768px) {
      margin-left: 4rem;

    }



  }

  &__frame {
    position: relative;

    .imageHeight {
      height: 100%;
      min-height: 500px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media(min-width:768px) {
        height: 100%;
        min-height: 506px;
      }

      img {
        width: 100px;
max-height: 75vh;
        @media (min-width: 768px) {
          max-width: 400px;
          position: relative;
          // z-index: 2;
        }
      }

      .dotes {
        background: linear-gradient(6deg,
            rgb(254, 171, 213) 72%,
            rgb(255, 255, 255) 100%);
        border-radius: 50%;
      }
  
      .short_dot {
        height: 30px;
        width: 30px;
        display: block;
        position: absolute;
        top: 64px;
        left: 21px;
        transform: translate(0, 0);
        transition: transform 0.3s ease;
      }
  
      .big_dots {
        height: 40px;
        width: 40px;
        display: block;
        right: 0px;
        bottom: 0;
        position: absolute;
        transform: translate(0, 0);
        transition: transform 0.3s ease;
      }
    }

    

  }

  &__body {
    // padding-top: 1rem;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;



    // @media (min-width: 768px) {
    //   padding-top: 6rem;
    // }
  }

  &__subtitle {
    line-height: 16px;
    font-family: "gilroy-font";
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 500;
    position: relative;
    letter-spacing: 0.5px;
  }

  &__title {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 40px;
    padding-top: 0;
    font-family: "gilroy-font";
    position: relative;
    text-align: center;

    &::before {
      content: "";
      border: 1px dashed #ff6bb5;
      position: absolute;
      left: 0px;
      top: -15px;
      width: 30%;
      z-index: 1;
      display: none;

      @media (min-width: 576px) {
        display: block;
      }
    }

    @media (min-width: 576px) {
      font-size: 3rem;
      line-height: 55px;
      padding-bottom: 0.5rem;
      text-align: left;
      padding-top: 1rem;
    }

    @media (min-width: 768px) {
      font-size: 3rem;
      line-height: 55px;
      padding-bottom: 0.5rem;
    }
  }

  &__description {
    font-size: 1rem;
    font-weight: 600 !important;
    line-height: 28px;
    font-family: "gilroy-font-light";
    margin-bottom: 2rem;
    text-align: center;

    @media (min-width: 576px) {
      text-align: left;
    }
  }

  &__search-box {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 93%;
    max-width: 100%;

    input {
      height: 50px !important;

      &.form-control {
        border-radius: 40px !important;
        padding: 0.375rem 2.75rem !important;
        outline: none;
        border: 1px solid #dbdbdb;
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.3);

        &:focus {
          outline: 0;
          box-shadow: unset !important;
          // color: unset !important;
        }
      }
    }

    .theme_btn {
      position: absolute;
      right: 5px;
      text-decoration: none;
      padding-inline: 20px;
      top: 3px;
      transform: translate(0px, 0px);

      @media (max-width: 576px) {
        top: 4px;
      }
    }

    .form-control-feedback {
      position: absolute;
      left: 20px;
      color: #b3b1b1;
      top: 18px;
      transform: translate(0px, 0px);
    }

    @media (min-width: 768px) {
      flex-direction: row;
      // padding-top: 1rem;
      align-items: center;
    }
  }

  .btn {
    margin-bottom: 0.75rem;

    @media (min-width: 768px) {
      margin-right: 1rem;
    }
  }
}

.BetterChoice_container {
  z-index: 2;
  position: relative;

  &::before {
    content: "";
    background-color: var(--color-black);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 70%;
    z-index: -1;
  }

  // Full height after md breakpoint (768px in Bootstrap)
  @media (max-width: 768px) {
    // height: 100vh;

    &::before {
      height: 100% !important;
    }
  }
}


.betterChoice {
  &__heading_text {
    padding-bottom: 80px;
  }

  &__title {
    color: #fff;
  }

  &__heading-desc {
    color: #fff;
    max-width: 900px;
    margin: auto;
    font-family: "gilroy-font-light";
    font-size: 1rem;
  }

  &__boxe {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    transition: 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 576px) {
      padding: 30px;
      align-items: start;
      justify-content: start;
    }

    @media (max-width: 576px) {
      padding: 15px;
      margin-bottom: 10px;
    }

    &.active {
      background-color: #ff8fc7;
      color: #fff;

      .betterChoice__icon {
        img {
          filter: invert(1);
        }
      }
    }

    &:hover {
      background-color: #ff8fc7;
      color: #fff;

      .betterChoice__icon {
        img {
          filter: invert(1);
        }
      }

      .betterChoice__sub-heading-text {
        &::after {
          background-color: #fff;
        }
      }
    }

    .betterChoice__sub-heading-text {
      position: relative;
      margin-bottom: 15px;

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        height: 2px;
        background-color: var(--color-pink);
        width: 100%;
        max-width: 40px;
        bottom: -6px;
        transform: translateX(-50%);

        @media(min-width: 576px) {
          left: 0;
          transform: translateX(0);

        }
      }
    }
  }

  &__sub-heading-desc {
    font-family: "gilroy-font-light";
    font-size: 1rem;
    font-weight: 400;
    text-align: center;

    @media(min-width: 576px) {
      text-align: left;
    }
  }

  &__sub-heading-text {
    font-family: "gilroy-font-light";
    font-weight: 600;
  }

  &__icon {
    margin-bottom: 20px;
    position: relative;

    i {
      font-size: 40px;
      color: #000;
      z-index: 2;
      position: relative;
    }

    &::before {
      content: "";
      background: #e6e6e6;
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 50px;
      z-index: 1;
      -webkit-clip-path: circle(50% at 50% 50%);
      clip-path: circle(50% at 50% 50%);
      opacity: 0.5;
    }
  }
}

.HowDoesit {
  &__heading_text {
    padding-bottom: 80px;
  }

  &__title {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -10px;
      border: 1px dashed var(--color-pink);
      width: 100%;
      max-width: 100px;
      left: 50%;
      transform: translate(-50%, 0px);
    }
  }

  &__heading-desc {
    color: #000;
    max-width: 900px;
    margin: auto;
    font-family: "gilroy-font-light";
    font-size: 1rem;
  }

  &__photo {
    height: 350px;
    width: 100%;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .accordion {
    .accordion-header {
      position: relative;

      &::before {
        content: "1";
        position: absolute;
        left: 0px;
        top: 0px;
        height: 40px;
        width: 40px;
        background-color: #d9d9d9;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        color: #000;
      }

      &.active {
        &::before {
          background: linear-gradient(180deg,
              rgba(254, 171, 213, 1) 40%,
              rgba(255, 255, 255, 1) 100%);
        }
      }

      &.accordion-header-two {
        position: relative;

        &::before {
          content: "2";
          position: absolute;
          left: 0px;
          height: 40px;
          width: 40px;
          background-color: #d9d9d9;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-weight: 600;
          color: #000000;
        }

        &.active {
          &::before {
            background: linear-gradient(180deg,
                rgba(254, 171, 213, 1) 40%,
                rgba(255, 255, 255, 1) 100%);
          }
        }
      }

      &.accordion-header-three {
        position: relative;

        &::before {
          content: "3";
          position: absolute;
          left: 0px;
          height: 40px;
          width: 40px;
          background-color: #d9d9d9;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-weight: 600;
          color: #000000;
        }

        &.active {
          &::before {
            background: linear-gradient(180deg,
                rgba(254, 171, 213, 1) 40%,
                rgba(255, 255, 255, 1) 100%);
          }
        }
      }
    }

    .accordion-item {
      border: unset !important;

      .accordion-body {
        background-color: #fff1f6;
        border-radius: 0px 20px 20px 20px;
        margin-left: 62px;
        max-width: 460px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 2px;
          background-color: #f1d1dc;
          height: 100%;
          top: -20px;
          left: -43px;
        }
      }
    }
  }

  .accordion-button {
    background-color: unset !important;
    padding: 10px 20px 10px 60px;
  }

  .accordion-button:focus {
    box-shadow: unset;
  }

  .accordion-button::after {
    opacity: 0;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: unset;
  }
}

.dentists_container {
  .dentist {
    &__body {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      position: relative;

      .dottend_image {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
        display: none;

        @media (min-width: 576px) {
          display: block;
        }

        img {
          height: 100px;
          width: 100px;
          margin-top: -15px;
        }
      }
    }

    &__title {
      img {
        height: 50px;
        width: 50px;
      }
    }

    &__photo {
      position: relative;

      .dottend_image {
        position: absolute;
        top: 50px;
        right: 136px;
        z-index: -1;
        display: none;

        @media (min-width: 576px) {
          display: block;
        }

        img {
          height: 100px;
          width: 100px;
        }
      }
    }
  }
}

// ===About==Us===

.about {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
  }

  &__photo {
    position: relative;

    .rectangle {
      background-color: #fdbec6;
      height: 340px;
      width: 400px;
      position: absolute;
      top: 22px;
      border-radius: 10px 0px 0px 0px;
      z-index: -1;
      max-height: 250px;
    }

    @media (max-width: 576px) {
      .rectangle {
        max-height: 200px;
        width: 300px;
      }
    }

    @media (max-width: 420px) {
      .rectangle {
        max-height: 200px;
        width: 280px;
      }
    }

    @media (max-width: 375px) {
      .rectangle {
        max-height: 150px;
        width: 220px;
      }
    }

    @media (min-width: 768px) {
      // margin-left: 3rem;
      position: relative;
    }

    img {
      @media (min-width: 768px) {
        max-width: 500px;
        position: relative;
        z-index: 2;
      }
    }

    img {
      border-radius: 4px;
    }
  }

  &__frame {
    position: relative;
    margin: 50px 30px;
    display: inline-block;
  }

  &__body {
    padding-top: 1rem;

    @media (min-width: 768px) {
      padding-top: 6rem;
    }
  }

  &__subtitle {
    line-height: 16px;
    font-family: "gilroy-font";
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 500;
    position: relative;
    letter-spacing: 0.5px;
    text-align: center;

    @media (min-width: 576px) {
      text-align: left;
    }

    &::before {
      content: "";
      border: 1px dashed #ff6bb5;
      position: absolute;
      left: 50%;
      width: 30%;
      top: -100%;
      transform: translateX(-50%);
      z-index: 1;

      @media (min-width: 576px) {
        left: 0px;
        transform: translateX(0%);
      }
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 40px;
    padding-top: 1rem;
    font-family: "gilroy-font";
    position: relative;
    text-align: center;

    @media (min-width: 576px) {
      text-align: left;
    }

    @media (min-width: 768px) {
      font-size: 3rem;
      line-height: 55px;
      padding-bottom: 0.5rem;
    }
  }

  &__description {
    font-size: 1rem;
    font-weight: 500;
    line-height: 28px;
    font-family: "gilroy-font-light";
    text-align: center;

    @media (min-width: 576px) {
      text-align: left;
    }
  }

  &__search-box {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    position: relative;

    input {
      height: 50px !important;

      &.form-control {
        border-radius: 40px !important;
        padding: 0.375rem 2.75rem !important;
        outline: none;
        border: 1px solid #dbdbdb;
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.3);

        &:focus {
          outline: 0;
          box-shadow: unset !important;
          color: unset !important;
        }
      }
    }

    .theme_btn {
      position: absolute;
      right: 5px;
      text-decoration: none;
      padding-inline: 20px;
    }

    .form-control-feedback {
      position: absolute;
      left: 20px;
      color: #b3b1b1;
    }

    @media (min-width: 768px) {
      flex-direction: row;
      padding-top: 1rem;
      align-items: center;
    }
  }

  .btn {
    margin-bottom: 0.75rem;

    @media (min-width: 768px) {
      margin-right: 1rem;
    }
  }
}

.whychoose {
  &__heading_text {
    padding-bottom: 50px;
  }

  &__title {
    color: var(--color-black);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -10px;
      border: 1px dashed var(--color-pink);
      width: 100%;
      max-width: 100px;
      left: 50%;
      transform: translate(-50%, 0px);
    }
  }

  &__heading-desc {
    color: var(--color-black);
    max-width: 900px;
    margin: auto;
    font-family: "gilroy-font-light";
    font-size: 1rem;
  }

  &__box {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    padding: 30px;

    width: 100%;
    border-radius: 6px;
    text-align: center;
    border: 1px solid #ff6bb5;
    transition: 0.3s ease-in-out;
    --c: no-repeat linear-gradient(#ff6bb5 0 0);
    background: var(--c) calc(-100% + var(--i, 0) * 100%) 100% / 50% calc(100% * var(--i, 0) + 0.08em),
      var(--c) calc(200% - var(--i, 0) * 100%) 0 / 50% calc(100% * var(--i, 0) + 0.08em);
    transition: 0.3s calc(var(--i, 0) * 0.3s),
      background-position 0.3s calc(0.3s - var(--i, 0) * 0.3s);

    &.active {
      background-color: #ff8fc7;
      color: #fff;

      .whychoose__icon {
        svg {
          fill: #fff;
        }
      }
    }

    &:hover {
      background-color: #ff8fc7;
      color: #fff;
      --1: 1;

      .whychoose__icon {
        svg {
          fill: #fff;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 20px;
    }
  }

  &__icon {
    margin-bottom: 20px;
    position: relative;

    svg {
      height: 40px;
      width: 40px;
    }

    &::before {
      content: "";
      background: #e6e6e6;
      position: absolute;
      top: 0;
      right: 40%;
      width: 50px;
      height: 50px;
      z-index: 1;
      -webkit-clip-path: circle(50% at 50% 50%);
      clip-path: circle(50% at 50% 50%);
      opacity: 0.5;
    }
  }
}

.dottend_image {
  img {
    height: 100px;
    width: 100px;
  }
}

// ====Find--Dentist-----

.find-dentist-container {
  background-image: url("./Assets/home_1_1_3.jpg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 0px;

  @media (min-width: 576px) {
    padding: 100px 0px;
  }

  .patient_form {
    .input-group {
      position: relative;

      .form__icons {
        position: absolute;
        left: 3%;
        top: 50%;
        z-index: 9 !important;
        font-size: 20px;
        transform: translate(-3%, -50%);
      }

      .form-control {
        height: 60px;
        border-right: none;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        padding: 10px 40px;
        border-radius: 0;
        width: 100%;
        border-radius: 4px;

        @media (min-width: 576px) {
          height: 80px;
          border-right: 2px solid #ff6bb5;
        }
      }
    }

    .patient_form__icon {
      span {
        color: #ff6bb5;
      }
    }

    .patient_form__sub-title {
      margin-left: 10px;
    }

    button {
      &.btn-two {
        height: 80px !important;
        cursor: pointer;
      }
    }
  }
}

.form {
  &__icons {
    color: #ff6bb5;
  }
}

.howitworks-container {
  .howitworks__boxes {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  .howitworks {
    &__heading_text {
      padding-bottom: 50px;
    }

    &__title {
      color: var(--color-black);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: -10px;
        border: 1px dashed #ff6bb5;
        width: 100%;
        max-width: 100px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }

    &__heading-desc {
      color: var(--color-black);
      max-width: 900px;
      margin: auto;
      font-family: "gilroy-font-light";
      font-size: 1rem;
    }

    .card {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      padding: 30px;
      background-color: #fff;
      border-radius: 6px;
      text-align: center;
      border: 1px solid #ff6bb5;
      transition: 0.3s ease-in-out;
      text-align: start;
      position: relative;
      z-index: 5;
      --c: no-repeat linear-gradient(#ff6bb5 0 0);
      background: var(--c) calc(-100% + var(--i, 0) * 100%) 100% / 50% calc(100% * var(--i, 0) + 0.08em),
        var(--c) calc(200% - var(--i, 0) * 100%) 0 / 50% calc(100% * var(--i, 0) + 0.08em);
      transition: 0.3s calc(var(--i, 0) * 0.3s),
        background-position 0.3s calc(0.3s - var(--i, 0) * 0.3s);
      flex: 1 0 300px;

      &:nth-child(1):before {
        content: "01";
        display: flex;
        align-items: flex-end;
        justify-content: end;
        position: absolute;
        bottom: 0px;
        right: 15px;
        z-index: -1;
        font-size: 75px;
        color: #dfdfdf;
        font-weight: 800;

        @media(min-width: 576px) {
          top: 5px;
          left: 35px;
        }
      }

      &:nth-child(2):before {
        content: "02";
        display: flex;
        align-items: flex-end;
        justify-content: end;
        position: absolute;
        bottom: 0px;
        right: 15px;
        z-index: -1;
        font-size: 75px;
        color: #dfdfdf;
        font-weight: 800;

        @media(min-width: 576px) {
          top: 5px;
          left: 35px;
        }
      }

      &:nth-child(3)::before {
        content: "03";
        display: flex;
        align-items: flex-end;
        justify-content: end;
        position: absolute;
        bottom: 0px;
        right: 15px;
        z-index: -1;
        font-size: 75px;
        color: #dfdfdf;
        font-weight: 800;

        @media(min-width: 576px) {
          top: 5px;
          left: 35px;
        }
      }

      // ::after {
      //   content: "";
      //   position: absolute;
      //   right: -3px;
      //   bottom: 13px;
      //   border: dashed #b5b5b5;
      //   border-width: 0 0.5px 0.5px 0;
      //   display: inline-block;
      //   padding: 3px;
      //   transform: rotate(-45deg);
      //   -webkit-transform: rotate(-45deg);
      // }

      &.active {
        background-color: #ff8fc7;
        color: #000000;
      }

      &:hover {
        background-color: #ff8fc7;
        color: #000000;
        --i: 1;

        .howitworks__desc {
          // border-bottom: 1px dashed #fff;
        }

        // ::after {
        //   content: "";
        //   position: absolute;
        //   right: -3px;
        //   bottom: 13px;
        //   border: dashed #ffffff;
        //   border-width: 0 0.5px 0.5px 0;
        //   display: inline-block;
        //   padding: 3px;
        //   transform: rotate(-45deg);
        //   -webkit-transform: rotate(-45deg);
        // }
      }
    }

    &__box:nth-child(2)::before {
      content: "02";
    }

    &__box:nth-child(3)::before {
      content: "03";
    }

    &__box:nth-child(4)::before {
      content: "04";
    }

    &__box:nth-child(5)::before {
      content: "05";
    }

    &__desc {
      // border-bottom: 1px dashed #b5b5b5;
      padding-bottom: 30px;
    }
  }
}

// ===Career=====

.career {
  .hero__photo {
    position: relative;
    margin-left: 0px !important;

    .sub_image {
      height: auto;
      width: 200px;
      border-radius: 4px;
      border-inline-start: 10px solid #fff;
      z-index: 2;
      border-block-start: 10px solid #fff;
      border-radius: 6px;
      position: absolute;
      bottom: 2%;
      right: -7%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 6px;
      }
    }

    .dottend_image {
      position: absolute;
      top: 5%;
      right: -3%;
      z-index: 1;
    }
  }
}

.join-dentist {
  &__title {
    color: var(--color-black);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -20px;
      border: 1px dashed #ff6bb5;
      width: 100%;
      max-width: 150px;
      left: 42%;
    }
  }

  &__heading-desc {
    color: #000;
    max-width: 900px;
    margin: auto;
    font-family: "gilroy-font-light";
    font-size: 1rem;
  }

  .join-dentist_boxes {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    justify-content: center;
    padding: 4.5rem 0;
    position: relative;
    background-image: url("./Assets/career-bg.png");
    background-position: top 35% center;
    background-size: cover;
    background-repeat: no-repeat;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: 0.79;
    }
  }

  &__boxe {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    padding: 30px;
    max-width: 340px;
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    transition: 0.3s ease-in-out;
    position: relative;

    &.active {
      background-color: #ff8fc7;
      color: #fff;
    }

    &:hover {
      background-color: #ff8fc7;
      color: #fff;

      svg {
        fill: #3b3a3a;
      }
    }

    .join_dentist__bg_blobs {
      position: absolute;
      top: 10px;
      right: 100px;
      z-index: 1;

      svg {
        fill: #f6f6f6;
      }
    }

    @media (min-width: 768px) {
      .join_dentist__sub-heading-desc {
        font-size: 18px;
      }
    }
  }

  &__icon {
    padding-bottom: 55px;

    svg {
      height: 50px;
      width: 50px;
      display: block;
      margin: 0 auto;
      fill: #ff6bb5;
      position: relative;
      z-index: 2;
    }
  }
}

.culture {
  &__heading_text {
    padding-bottom: 60px;
  }

  &__title {
    color: var(--color-black);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -15px;
      border: 1px dashed #ff6bb5;
      width: 100%;
      max-width: 100px;
      left: 45%;
    }
  }

  &__heading-desc {
    color: #000;
    max-width: 800px;
    margin: auto;
    font-family: "gilroy-font-light";
    font-size: 1rem;
  }

  &__photo {
    position: relative;
    padding: 30px 0;

    .d-img-one {
      position: absolute;
      top: -3%;
      left: -4%;
      z-index: -1;
    }

    .d-img-two {
      position: absolute;
      right: -4%;
      bottom: 2%;
      z-index: -1;
    }
  }

  .culture__body {
    padding-left: 50px;
  }

  &__body-cntent {
    display: flex;
    align-items: top;
    border-bottom: 1px dashed #b5b5b5;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      .culture__text-heading {
        font-size: 18px;
      }
    }
  }

  &__icons {
    margin-right: 25px;

    svg {
      height: 40px;
      width: 40px;
      fill: #ff6bb5;
    }
  }
}

.join-us {
  &__body {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    flex-wrap: wrap;
  }

  &__box {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    padding: 30px;
    max-width: 250px;
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    transition: 0.3s ease-in-out;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__desc {
    color: #8d8d8d;
    font-weight: 600;
  }
}

// ====Support====

.support_container {
  background-image: url("./Assets/support_bg.jpg");
  background-position: 60%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  padding: 60px 0;



  @media (min-width: 576px) {
    padding: 100px 0;
    background-position: center center;
  }

  .support {
    &__left-content {
      max-width: 800px;
      width: 100%;
    }

    &__sub-title {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: -20px;
        border: 1px dashed #ff6bb5;
        width: 100%;
        max-width: 90px;
        left: 0%;
      }
    }
  }
}

.help {
  &__heading_text {
    padding-bottom: 80px;
    text-align: center;
  }

  &__main_title {
    color: var(--color-black);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -10px;
      border: 1px dashed #ff6bb5;
      width: 100%;
      max-width: 100px;
      left: 50%;
      transform: translate(-50%, 0px);
    }
  }

  &__subtitle {
    color: var(--color-black);
    max-width: 900px;
    margin: auto;
    font-family: "gilroy-font-light";
    font-size: 1rem;
    text-align: center;
  }

  &__box {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    padding: 20px;
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    text-align: center;
    border: 1px solid #ff6bb5;
    transition: 0.3s ease-in-out;
    position: relative;
    z-index: 5;

    @media (min-width: 768px) {
      padding: 30px;
      margin-bottom: 40px;
    }

    @media (max-width: 768px) {
      margin-bottom: 60px;
    }
  }

  &__circle {
    position: relative;
    top: 5px;
    left: 5px;
    text-align: center;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: #ffffff;
    display: grid;
    place-content: center;
    border: 1px solid #ff6bb5;

    i {
      font-size: 30px;
      color: var(--color-pink);
    }
  }

  &__circle-border {
    position: relative;
    text-align: center;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background-color: #ff8fc7;
    background-image: linear-gradient(358deg, transparent 50%, #ffff 50%),
      linear-gradient(182deg, #ffff 50%, transparent 50%);
    margin: auto;
    margin-top: -72px;
    margin-bottom: 30px;
  }
}

.faq {
  &__title {
    position: relative;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      top: -10px;
      border: 1px dashed #ff6bb5;
      width: 100%;
      max-width: 130px;
      left: 50%;
      transform: translateX(-50%);

      @media (min-width: 576px) {
        left: 0;
        transform: translateX(0%);
      }
    }

    @media (min-width: 576px) {
      text-align: left;
    }
  }

  &__descripion {
    text-align: center;

    @media (min-width: 576px) {
      text-align: left;
    }
  }

  &__accordion {
    .accordion-item {
      // border: 0 !important;
      border: none !important;
      border-bottom: 1px solid grey !important;
      border-radius: 0 !important;
    }

    .accordion-button:focus {
      box-shadow: unset;
    }

    .accordion-button:not(.collapsed) {
      box-shadow: unset !important;
    }

    .accordion-button::after {
      opacity: 0 !important;
    }

    .accordion-button::before {
      content: "\002B";
      right: 0;
      position: absolute;
      border: 1px solid #ff6bb5;
      padding: 6px 5px 5px 7px;
      color: var(--color-pink);
      height: 25px;
      width: 25px;
      display: grid;
      place-content: center;
      font-size: 25px;
      font-weight: 500;
      line-height: 5px;
      text-align: center;
      border-radius: 50%;
    }

    .accordion-button:not(.collapsed)::before {
      content: "\2212";
      background-color: var(--color-pink);
      color: #fff;
    }
  }
}

// ====Dentist-List====

.dentist-list__map {
  position: sticky;
  top: 115px;
  height: auto;
  right: 0;
  width: 100%;

  .map_ofList {
    height: 100vh;
    width: 100%;
    position: sticky;
    top: 0px;

    @media(max-width: 576px) {
      height: 50vh;
    }
  }
}

.dentist-tabs {
  .MuiTabs-flexContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .step-box {
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;
  }

  .step-box-content {
    width: 100%;
    padding: 35px;
    margin-top: 20px;
    text-align: center;
    transition: 0.3s ease-in;
    border-radius: 4px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

    &:hover {
      background-color: #ff6bb5;
      color: #fff;
    }
  }

  .select-form {
    .form-select-lg {
      font-size: 1rem !important;
    }
  }
}

// ===Dentist-Profile====

.profile-container {
  .about-profile {
    header {
      background-color: #000000;
    }

    .photos-widget {
      .slick-slider {

        .slick-slide {
          padding-inline: 1px;
        }

        .slick-list [data-slick-index="0"] {
          padding-inline: 1px;
        }

        .slick-dots {
          bottom: -40px;
        }

        .slick-next {
          right: 0;
        }

        .slick-prev {
          left: 0;
          z-index: 1 !important;
        }

      }
    }

    // .about-photos {
    //   display: flex;
    //   flex-wrap: wrap;

    //   .images {
    //     position: relative;
    //     width: calc(50% - 2px);
    //     height: 230px;
    //     margin: 1px;
    //     display: flex;
    //     -webkit-box-pack: center;
    //     justify-content: center;
    //     -webkit-box-align: center;
    //     align-items: center;
    //     overflow: hidden;
    //     border-radius: 4px;

    //     &:nth-child(n+3) {
    //       width: calc(33.33% - 2px);
    //       height: 140px;
    //     }

    //     &:nth-child(5)::after {
    //       position: absolute;
    //       content: "See all photos";
    //       color: white;
    //       top: 0px;
    //       left: 0px;
    //       width: 100%;
    //       height: 100%;
    //       background-color: rgba(0, 0, 0, 0.35);
    //       display: flex;
    //       -webkit-box-align: center;
    //       align-items: center;
    //       -webkit-box-pack: center;
    //       justify-content: center;
    //       pointer-events: none;

    //     }

    //     img.clinicImages {
    //       width: 100%;
    //       min-height: 100%;
    //       object-fit: cover;
    //       vertical-align: middle;
    //       border-style: none;
    //     }

    //   }
    // }

    .widget {
      margin-bottom: 30px;
    }

    .experience-box {
      position: relative;

      .experience-list {
        list-style: none;
        position: relative;
        margin: 0;
        padding: 0;

        &::before {
          background: #dddddd;
          bottom: 0;
          content: "";
          width: 2px;
          position: absolute;
          top: 8px;
          left: 9px;
        }

        li {
          position: relative;

          .experience-user {
            background: #ffffff;
            height: 10px;
            width: 10px;
            position: absolute;
            top: 4px;
            left: 4px;
            margin: 0;
            padding: 0;

            .before-circle {
              background-color: rgba(252, 190, 246, 0.2);
              height: 12px;
              width: 12px;
              border: 2px solid #ff6bb5;
              border-radius: 50%;
            }
          }

          .experience-content {
            background-color: #ffffff;
            position: relative;
            margin: 0 0 20px 40px;
            padding: 0;

            .timeline-content {
              color: #757575;

              a {
                color: #2e3842;
              }

              span {
                font-size: 14px;
                display: block;
              }

              .exp-year {
                font-weight: 600;
                color: #000;
              }
            }
          }
        }
      }
    }

    .location-list {
      border: 1px solid #f0f0f0;
      border-radius: 4px;
      padding: 20px;
      margin-top: 20px;

      .clinic-content {
        .clinic-name {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 3px;

          a {
            color: #2e3842;
            font-weight: 800;
          }

          .doc-speciality {
            font-size: 14px;
            color: #757575;
            margin-bottom: 15px;
          }
        }

        .rating {
          list-style: none;
          width: 100%;
          margin: 0 0 7px;
          padding: 0;
        }

        .rating i.filled {
          color: #ff8fb0;
        }

        .clinic-details {
          h5 {
            font-weight: normal;
            color: #757575;
            margin-bottom: 25px;

            &.clinic-direction {
              font-size: 16px;
            }

            a {
              display: inline-block;
              margin-top: 10px;
            }
          }

          ul {
            li {
              display: inline-block;
              margin-right: 5px;

              a {
                display: inline-block;

                img {
                  height: 40px;
                  border-radius: 5px;
                }
              }
            }
          }
        }
      }

      .clinic-timing {
        .timings-days {
          font-weight: 700;
          color: #272b41;
          margin-bottom: 5px;
        }

        .timings-times {
          span {
            display: block;
          }
        }
      }
    }

    .review-listing {
      ul {
        li {
          .comment {
            display: flex;
            margin-bottom: 30px;

            .avatar-sm {
              height: 2.5rem;
              width: 2.5rem;
              object-fit: cover;
            }

            .comment-body {
              margin-left: 16px;

              .meta-data {
                position: relative;
                margin-bottom: 10px;

                .comment-author {
                  font-weight: 600;
                  color: #272b41;
                  text-transform: capitalize;
                }

                .comment-date {
                  font-size: 14px;
                  display: block;
                  color: #757575;
                }

                .review-count {
                  position: absolute;
                  top: 3px;
                  right: 0;

                  &.rating i.filled {
                    color: #ff8fb0;
                  }

                  &.rating i {
                    color: #dedfe0;
                  }
                }
              }

              .recommended {
                color: #28a745;
                font-size: 15px;
                font-weight: 500;
                margin: 0;
              }

              .comment-content {
                margin: 15px 0;
                font-size: 14px;
              }

              .comment-reply {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .comment-btn {
                  color: #ff8fb0;
                  display: inline-block;
                  font-weight: 600;
                  font-size: 15px;
                }

                .recommend-btn {
                  color: #757575;
                  font-size: 14px;
                  padding: 5px 0;
                  margin-bottom: 0;

                  a {
                    border: 1px solid rgba(128, 137, 150, 0.4);
                    display: inline-block;
                    color: #757575;
                    -webkit-transition: all 0.3s;
                    -ms-transition: all 0.3s;
                    transition: all 0.3s;
                    border-radius: 4px;
                    margin: 0 3px;
                    padding: 4px 12px;
                  }
                }
              }
            }
          }

          .comments-reply {
            list-style: none;
            margin-left: 65px;
            padding: 0;
          }
        }
      }
    }

    .write-review {
      .star-rating {
        float: right;

        input {
          display: none;
        }

        label {
          color: #bbb;
          cursor: pointer;
          font-size: 18px;
          padding: 0;
          -webkit-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }

        input[type="radio"]:checked~label {
          color: #ff8fb0;
        }
      }
    }

    .business-widget {
      background-color: #fff;
      border: 1px solid #f0f0f0;
      margin-bottom: 0;
      padding: 20px;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

      .listing-day {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 10px;

        &.current {
          border-bottom: 1px solid #dddddd;
          padding-bottom: 13px;
          margin-bottom: 13px;

          .day {
            font-weight: 700;

            span {
              display: block;
              font-weight: 500;
            }
          }

          .time-items {
            display: flex;
            flex-direction: column;
            align-items: end;

            span {
              display: inline-block;
              font-weight: 600;
              padding: 5px;
            }
          }
        }
      }
    }
  }
}

// ====Appointment=====

.appointment {
  .booking-doc-img {
    width: 80px;
    margin-right: 15px;

    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  .booking-info {
    .rating {
      padding: 5px 0;
    }

    .rating i.filled {
      color: #ff8fb0;
    }

    .rating i {
      color: #dedfe0;
    }
  }

  .schedule-widget {
    border-radius: 4px;

    .schedule-header {
      border-bottom: 1px solid #f0f0f0;
      padding: 10px 20px;
      border-radius: 4px 4px 0 0;
    }

    .day-slot {
      ul {
        padding: 0px;
        margin: 0px;
        position: relative;
        width: 100%;
        margin: auto;

        li {
          float: left;
          padding-left: 5px;
          padding-right: 5px;
          text-align: center;
          width: 14.28%;
        }

        li.left-arrow {
          text-align: center;
          width: 20px !important;
          transform: translateY(0%);
          /* -webkit-transform: translateY(-50%); */
          -ms-transform: translateY(-50%);
          position: absolute;
          top: 50%;
          left: -10px;
          padding: 0;
          transform: translateY(50%);
        }

        li.right-arrow {
          text-align: center;
          width: 20px !important;
          transform: translateY(0%);
          // -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          position: absolute;
          top: 50%;
          right: -10px;
          padding: 0;
          transform: translateY(50%);
        }
      }
    }

    .schedule-cont {
      padding: 20px;

      .time-slot {
        ul {
          li {
            display: inline-block;
            margin-bottom: 15px;
            padding-left: 5px;
            padding-right: 5px;
            width: 14.28%;

            .timing {
              background-color: #e9e9e9;
              border: 1px solid #e9e9e9;
              border-radius: 3px;
              color: #757575;
              display: block;
              font-size: 14px;
              margin-bottom: 10px;
              padding: 5px 5px;
              text-align: center;
              position: relative;

              &:hover {
                background: #ffffff;
                box-shadow: inset 0 0 0 50px #ffffff;
                -webkit-transition: 0.7s;
                -ms-transition: 0.7s;
                transition: 0.7s;
              }

              &.selected {
                background-color: #ff8fb0;
                border: 1px solid #ff6bb5;
                color: #ffffff;

                &:hover {
                  color: #000;
                }

                &:hover::before {
                  color: #000;
                }

                &::before {
                  color: #ffffff;
                  content: "\f00c";
                  font-family: "Font Awesome 5 Free";
                  font-size: 12px;
                  font-weight: 900;
                  position: absolute;
                  top: 6px;
                  right: 6px;
                }
              }

              &.available {
                background-color: #ffffff;
                border: 1px solid #ff6bb5;
                color: #000;

                &:hover {
                  background-color: #ff8fb0;
                }
              }
            }
          }
        }
      }
    }
  }
}

// ----Appointment----

.appointment {
  .sigma_post-details-inner {
    .spacer {
      padding: 20px 0px;
    }

    .sigma_team {
      // border: 1px solid #e7e7e7;
      padding: 20px;
    }

    .p-scton {
      height: 100%;
      width: 100%;
    }

    .sigma_team-thumb {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 300px;
      height: 250px;
      overflow: hidden;
      border: 15px solid #f3f2f2;
      border-radius: 8px;
      // filter: drop-shadow(0 0 0.75rem rgb(214, 214, 214));
      border: 10px solid #ffffff;

      img {
        width: 100%;
        min-height: 100%;
        object-fit: cover;
        border-radius: 6px;
      }
    }

    .sigma_team-body {
      .sigma_team-info {
        span {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #757575;

          i {
            color: #ff8fc7;
            margin-right: 10px;
          }
        }
      }

      .sigma_rating {
        i {
          color: #e7e7e7;

          &.filled {
            color: #ff8fc7;
          }
         
        }
      }
    }

    .detail-menu-list {
      background-color: #000000;
      box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
      margin: 0 0 40px;
      position: sticky;
      top: 65px;
      z-index: 2;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: start;
      overflow: auto;
      scroll-snap-type: x mandatory;

      @media (min-width: 576px) {
        justify-content: space-around;
      }

      .menu {
        padding: 10px 14px;

        @media (min-width: 576px) {
          padding: 16px 20px;
        }

        a {
          color: #fff;
          position: relative;
          text-wrap: nowrap;

          &.active::after {
            opacity: 1;
          }

          &:hover::after {
            opacity: 1;
            // transform: translateX(50%);
          }

          &::after {
            content: "";
            background-color: #ff4fa7;
            height: 2px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: -6px;
            opacity: 0;
            transition: 0.2s ease;
            // transform: translateX(0);
          }
        }
      }
    }

    #overview {
      ul {
        li {
          display: inline-block;
          margin-bottom: 10px;
          align-items: center;
          gap: 10px;
        }
      }

      .sigma_general-list {
        &.style-3 {
          ul {
            li {
              display: block;

              div {
                padding: 10px;
                background-color: #f3f3f3;
                display: flex;
                gap: 10px;

                i {
                  height: 20px;
                  width: 20px;
                  background-color: #ff8fc7;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    #contact {
      margin: 30px 0px;

      .sigma_contact-map {
        iframe {
          width: 100%;
        }
      }

      .sigma_contact-blocks {
        padding: 50px 30px;
        border: 2px solid #ebf2f3;
        border-top: 0;
        border-radius: 6px;
      }

      .sigma_contact-wrapper {
        .sigma_ -blocks {
          .sigma_contact-block {
            display: flex;
            align-items: flex-start;

            i {
              color: #ff8fc7;
            }

            .contact-block-inner {
              margin-left: 10px;

              p {
                margin: 0px;
                font-weight: 600;
              }
            }
          }
        }
      }

      .service-categories {
        .tags {
          margin-bottom: 0px;
          padding: 0px;

          li {
            display: inline-block;
            margin: 0px;

            a {
              background-color: #fff;
              border: 1px solid #b3b1b1;
              color: #000000;
              padding: 10px;
              font-size: 16px;
              display: block;
              margin: 3px;
            }
          }
        }
      }

      .service-list {
        margin-bottom: 30px;

        ul {
          li {
            display: inline-block;
            padding-left: 25px;
            position: relative;
            margin: 6px 0;
            background: #eeeeee;
            margin: 2px;
            padding: 10px;
            text-align: center;
          }
        }
      }
    }

    #reviews {
      .review_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1.5rem;
        align-items: center;
      }

      .sigma_testimonial {
        position: relative;
        // margin-bottom: 30px;

        &.style-14 {
          border: 1px solid #ededed;
          padding: 25px;
          border-radius: 6px;
          display: flex;
          background-color: #fff;
          text-align: left;
          margin-bottom: 15px
        }

        .sigma_testimonial-thumb {
          img {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 20px;
            vertical-align: middle;
          }
        }

        .sigma_author-block {
          h5 {
            font-size: 18px;
            font-weight: 800;
          }
        }

        .sigma_testimonial-date {
          font-weight: 800;
          font-size: 16px;
        }

        .review_date {
          span {
            font-weight: 800;
            font-size: 16px;
          }
        }
      }
    }
  }

  .sidebar {
    position: sticky;
    top: 65px;

    .widget {
      border: 1px solid #e7e7e7;
      margin-bottom: 20px;

      .widget-title {
        padding: 20px;
        background-color: #ebf2f3;
        font-size: 20px;
        margin: 0 0 30px;
        border-radius: 6px 6px 0 0;
      }

      .widget-inner {
        .form-group {
          position: relative;
          margin-bottom: 10px;

          i {
            position: absolute;
            top: 10px;
            right: 20px;
          }
        }
      }

      .sigma_info {
        margin-bottom: 30px;
        display: flex;
        position: relative;
        border-radius: 6px;
        background-color: #fdfdfd;
        border: none;
        gap: 10px;
        margin: 10px;
        padding: 0px 10px;

        @media (min-width: 991px) {
          margin: 30px;
        }

        @media (min-width: 768px) {
          margin: 10px;
        }

        @media (max-width: 768px) {
          width: 100%;
        }

        .contct-txt {
          p {
            font-weight: 700;
          }
        }

        .sigma_info-title {
          span {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            background-color: #ffe6f2;
            border-radius: 50%;

            i {
              color: #ff8fc7;
            }

            @media (min-width: 576px) {
              height: 40px;
              width: 40px;
              font-size: 20px;
              border-radius: 50%;
            }
          }
        }
      }

      .sigma_author-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        .profile {
          height: 150px;
          width: 150px;
          overflow: hidden;
          border: 2px solid #ff8fc7;
          margin-bottom: 10px;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .sigma_author-box {
        ul {
          li {
            display: inline-block;
            padding: 20px 10px;
          }
        }
      }
    }
  }
}

.first-step {
  p.line {
    display: flex;
    align-items: center;
    font-size: 15px;
    flex-direction: column;

    @media (min-width: 576px) {
      flex-direction: row;
    }

    &::before,
    &:after {
      flex: 1;
      content: "";
      padding: 1px;
      background-color: #565656;
      margin: 5px;

      @media (max-width: 576px) {
        display: none;
      }
    }
  }
}

.third-step {
  .card {
    .basic-dtl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      // border-bottom: 1px solid #000;
      flex-wrap: wrap;

      .body2 {
        font-weight: bold;
      }

      .body1 {
        word-break: break-all;
      }
    }
  }
}

// --terms and Conditions---

.breadcrumb-bar-two {
  background: #f2f6f6;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 30px;

  .inner-banner {
    margin: 0;
    padding: 70px 0;

    .breadcrumb-title {
      font-size: 48px;
    }
  }
}

.term-condition {
  .terms-text {
    p {
      font-size: 16px;
      font-weight: 800;
      margin-bottom: 24px !important;

      i {
        color: #ff8fc7;
      }
    }

  }
}

// --Blog---Listing

.Blog-container {
  background-image: url("./Assets/Blogheader.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  padding: 100px 0;

  .page-description {
    .page-title {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: -20px;
        border: 1px dashed #ff6bb5;
        width: 100%;
        max-width: 90px;
        left: 0%;
      }
    }
  }
}

.blogs {
  .blog {
    border: 1px solid #f0f0f0;
    background-color: #ffffff;
    position: relative;
    border-radius: 4px;
    margin: 0 0 30px;
    padding: 1.5rem;

    .blog-image {
      margin: 0px 0px 30px;
      overflow: hidden;

      a {
        display: block;
        width: 100%;
        height: auto;
        position: relative;

        &:hover img {
          scale: 1.5;
        }
      }

      img {
        transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
      }
    }

    .blog-title {
      margin-bottom: 15px;

      a {
        color: #000;
      }
    }

    .blog-info {
      color: #757575;
      font-size: 14px;
      margin: 0 0 15px;

      .post-left {
        ul {
          li {
            display: inline-block;
            margin-right: 20px;

            i {
              color: #ff8fc7;
            }

            .post-author {
              a {
                color: #3b3a3a;
              }

              img {
                width: 28px;
                margin-right: 5px;
                border-radius: 100%;
              }
            }
          }
        }
      }
    }

    .blog-content {
      a {
        text-decoration: underline !important;
        color: #ff8fc7;
      }
    }
  }

  .blog-pagination {
    .page-item {
      &.active {
        .page-link {
          background-color: #ff8fc7;
          border: 1px solid #ff8fc7 !important;
          color: #fff !important;
        }
      }

      &.disabled {
        .page-link {
          color: #757575 !important;
        }
      }

      .page-link {
        color: #ff8fc7 !important;
      }
    }
  }

  .theiaStickySidebar {
    position: sticky;
    top: 0;

    .card {
      .card-header {
        background-color: #ebf2f3 !important;
        margin: 0 0 20px;
        border-radius: 6px 6px 0 0;
        padding: 25px 20px !important;

        .card-title {
          font-size: 20px;
          margin-bottom: 0px;
        }
      }

      .card-body {
        ul {
          li {
            display: table;
            width: 100%;
            margin-bottom: 15px;

            a {
              color: #3b3a3a;
            }

            .post-thumb {
              width: 80px;
              overflow: hidden;
              float: left;
              border-radius: 4px;
            }

            .post-info {
              margin-left: 95px;

              a {
                color: #3b3a3a;
              }

              p {
                font-size: 14px;
                font-weight: 600;
                color: #ff8fc7;
              }
            }
          }
        }

        ul.tags {
          li {
            display: inline-block;
            width: unset;
            margin: 0px;

            a {
              background-color: #ffffff;
              color: #272b41;
              position: relative;
              font-size: 14px;
              border: 1px solid #cccccc;
              display: inline-block;
              border-radius: 4px;
              margin: 0 0.5rem 0.5rem 0;
              padding: 5px 10px;
              transition: 0.3s all;

              &:hover {
                background-color: #ff8fc7;
                color: #fff;
                border: 1px solid #ff8fc7;
              }
            }
          }
        }
      }
    }
  }
}

//FUll blog Page

.view-blogs {
  .card {
    border: 1px solid #f0f0f0 !important;

    .card-header {
      padding: 20px;
      border-bottom: 1px solid #f0f0f0 !important;

      .card-title {
        font-size: 20px;
        margin-bottom: 0;
      }
    }

    .card-body {
      ul {
        &.social-share {
          li {
            display: inline-block;
            padding-right: 15px;

            a {
              padding: 10px;
              border: 1px solid #f0f0f0;
              color: #ff8fc7;

              &:hover {
                background-color: #ff8fc7;
                color: #fff;
              }
            }
          }
        }
      }

      .about-author {
        display: flex;
        justify-content: center;
        gap: 20px;

        .author-img-wrap {
          height: 100px;
          width: 100px;
          border-radius: 100%;
          overflow: hidden;
        }
      }

      .comments-list {
        list-style: none;
        position: relative;
        margin: 0;
        padding: 0;

        &.reply {
          li {
            clear: both;
            padding-left: 80px;
          }
        }

        li {
          .comment {
            display: flex;
            gap: 20px;
            margin-bottom: 20px;
          }

          .comment-author {
            img {
              width: 58px;
              height: 58px;
              border-radius: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

// ----Patient---

.patient {
  margin: 50px 0px;

  .profile-sidebar {
    border: 1px solid #e9e9e9;
    position: sticky;
    top: 10%;
    border-radius: 6px;

    .pro-widget-content {
      border-bottom: 1px solid #f0f0f0;
      margin: 0;
      padding: 20px;
      text-align: center;

      .profile-info-widget {
        display: block;
        text-align: center;

        .booking-doc-img {
          display: inline-block;
          height: 100px;
          width: 100px;
          margin: auto;
          margin-bottom: 15px;
          border: 2px solid #ff8fc7;
          border-radius: 100%;
          overflow: hidden;

          &:hover {
            img {
              scale: 1.5;
            }
          }

          img {
            transition: scale 400ms;
            height: 100%;
            width: 100%;
          }
        }

        .profile-det-info {
          h6 {
            color: #3b3a3a;

            i {
              color: #ff8fc7;
            }
          }
        }
      }
    }

    .dashboard-widget {
      .dashboard-menu {
        ul {
          font-size: 14px;
          line-height: 17px;
          text-transform: capitalize;
          display: flex;
          overflow: auto;
          width: 100%;

          @media (min-width: 576px) {
            display: block;
          }

          li {
            border-bottom: 1px solid #f0f0f0;
            position: relative;

            .custome-tab {
              color: #757575;
              display: flex;
              padding: 16px 20px;
              flex-direction: column;
              align-items: center;
              transition: 0.3s ease-in-out;
              text-wrap: nowrap;
              gap: 5px;

              @media (min-width: 576px) {
                display: block;
              }

              .unread-msg {
                margin-left: 20px;
                background: #fff;
                font-size: 14px;
                border-radius: 50%;
                text-align: center;
                color: #565656;
                padding: 6px;
                right: 10px;
                position: absolute;
                top: 10px;
                display: none;
              }

              &.active {
                background-color: #ff8fc7;
                color: #ffff;

                i {
                  color: #fff;
                }
              }

              i {
                color: #ff8fc7;
                text-align: center;
              }

              span {
                margin-left: 10px;
              }

              &:hover {
                background-color: #ff8fc7;
                color: #ffff;

                i {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  .patient__main-page {
    .profile-widget {
      background-color: #fff;
      border: 1px solid #f0f0f0;
      position: relative;
      margin-bottom: 30px;
      padding: 15px;
      border-radius: 4px;
      transition: all 0.3s ease;

      .doc-img {
        position: relative;
        overflow: hidden;
        z-index: 1;
        border-radius: 4px;

        &:hover {
          img {
            scale: 1.5;
          }

          .fav-btn {
            opacity: 1;
            visibility: visible;
            background-color: #ff8fc7;
            transform: translateX(100%);
            color: #fff;
          }
        }

        img {
          border-radius: 4px;
          transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
          width: 100%;
        }

        .fav-btn {
          position: absolute;
          top: 10px;
          right: 50px;
          height: 30px;
          width: 30px;
          color: #2e3842;
          opacity: 0;
          visibility: hidden;
          z-index: 99;
          border-radius: 3px;
          padding: 10px;
          display: grid;
          place-content: center;
        }
      }

      .pro-content {
        padding: 15px 0 0;

        .title {
          font-size: 17px;
          font-weight: 500;
          margin-bottom: 5px;
          color: #3b3a3a;

          i {
            color: #ff8fc7;
            margin-left: 10px;
          }
        }

        .speciality {
          font-size: 13px;
          color: #757575;
          margin-bottom: 5px;
        }

        .rating {
          list-style: none;
          width: 100%;
          margin: 0 0 7px;
          padding: 0;

          i {
            color: #757575;

            &.filled {
              color: #ff8fb0;
            }
          }
        }

        .available-info {
          font-size: 13px;
          color: #5c5c5c;
          font-weight: 500;
          list-style: none;
          margin: auto auto 15px !important;
          padding: 0px;
        }
      }
    }

    .form-control {
      box-shadow: unset !important;
    }

    .change-avatar {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 20px;
      width: 100%;

      .profile-img {
        height: 100px;
        width: 100px;
        object-fit: cover;
        overflow: hidden;
      }

      .upload-img {
        input {
          display: none;
        }
      }
    }

    .notification {
      .notification__list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          border-bottom: 1px solid #f5f5f5;
          padding: 0;
          margin: 0;
          width: 100%;

          a {
            display: block;
            padding: 10px 15px;
            border-radius: 2px;
            width: 100%;

            .avatar {
              margin-right: 10px;
              width: auto;
              height: auto;

              img {
                height: 46px;
                width: 46px;
                border-radius: 5px;
                margin-right: 8px;
              }
            }

            .media-body {
              width: 100%;
              color: #565656;

              h6 {
                color: #000;
              }

              span {
                font-size: 12px;
                line-height: 1.35;
                display: block;
                margin-top: 4px;

                &.notification-time {
                  color: #000;
                  float: right;
                }

                &.noti-title {
                  color: #ff4fa7;
                }
              }
            }
          }
        }
      }
    }
  }
}

// --iMg0-Modal--

.images {
  .modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);

    img.modal-content {
      margin: auto;
      width: 100%;
      max-width: 500px;
      display: block;
      -webkit-animation-name: zoom;
      -webkit-animation-duration: 0.6s;
      animation-name: zoom;
      animation-duration: 0.6s;

      @media(max-width: 576px) {
        max-width: 300px;
      }
    }

    .image-modal {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .prev-btn {
      color: #ff4fa7;
      font-size: 30px;
      position: absolute;
      top: 50%;
      left: 2%;
      transform: translate(-2%, -50%);
      z-index: 2;

    }

    .next-btn {
      color: #ff4fa7;
      font-size: 30px;
      position: absolute;
      top: 50%;
      right: 2%;
      transform: translate(-2%, -50%);
      z-index: 2;
    }

    .close {
      position: absolute;
      top: 15px;
      right: 35px;
      color: #f1f1f1;
      font-size: 40px;
      font-weight: bold;
    }

    .btn:hover,
    .btn:focus {
      color: #bbb;
      text-decoration: none;
      cursor: pointer;
    }

    .close:hover,
    .close:focus {
      color: #bbb;
      text-decoration: none;
      cursor: pointer;
    }
  }

  @-webkit-keyframes zoom {
    from {
      -webkit-transform: scale(0);
    }

    to {
      -webkit-transform: scale(1);
    }
  }

  @keyframes zoom {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }
}

// ---chat--Box---

/////////Switch btn////////

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  width: 16px;
  aspect-ratio: 1;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #ff8fb0;
}

input:focus+.slider {
  box-shadow: 0 0 1px #ff8fb0;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

// prabh css start for Doctor pages 14oct
.doctorWrap {
  // margin-top: 0.5rem;

  .docPageAdd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    +.row {
      row-gap: 1.5rem;
    }
  }

  .doctorCard {
    position: relative;
    background: #fff;
    box-shadow: 0 0 9px rgb(255 143 176 / 50%);
    border-radius: 13px;
    overflow: hidden;
    height: 26rem;

    .listSwitch {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;

      .slider,
      .slider::before {
        border-radius: 50px;
      }
    }

    .docImg {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .editIc {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      width: 2rem;
      background: #fff;
      border-radius: 50%;
      padding: 5px;
      cursor: pointer;
    }

    .docInfo {
      padding: 1.2rem 1rem;
      text-align: center;
    }
  }

  .theme_btn {
    height: 2.8rem;
  }

  h2 {
    color: red;
  }
}

// prabh css end for Doctor pages 14oct

.H100vh {
  height: 100vh;
}

.profDltWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  .dltIc {
    color: #ffffff;
    background: #bd2a2a;
  }

  .dltIc:hover {
    color: #ffffff;
    background: #bd2a2a;
  }
}

// prabh navigationbar and sidebar css start
.navigationBarWrapper {
  margin: 2rem 0;
  padding: 0;

  @media (min-width: 576px) {
    margin: 3rem 0;
  }

  ul {
    display: flex;
    gap: 10px;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 991px) {
      justify-content: left;
    }
  }

  li:hover {
    box-shadow: 2px 2px 5px #ffe1f0;
    transition: box-shadow 0.6s ease-in-out;
  }

  li a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    background: #fff;
    border: 1px solid #ffe1f0;
    width: 8rem;
    padding: 1rem 0.8rem;
    border-radius: 5px;
    color: #000;
    position: relative;
    height: 100%;

    @media (min-width: 768px) {
      padding: 1.2rem 0.8rem;
    }

    .notif {
      background-color: #ff6493;
      height: 30px;
      width: 30px;
      display: grid;
      place-content: center;
      border-radius: 50%;
      color: #fff;
      position: absolute;
      top: -16px;
      right: -5px;
    }

    i {
      height: 15px;
      color: deeppink;
    }
  }
}

.sidebarWrapper {
  ul li {
    padding: 1rem 0.2rem;
    padding-bottom: 0;
  }

  ul li.liHead {
    .dentist_listing {
      max-height: 160px !important;
      overflow-y: auto;
    }

    .st_list {
      display: flex;
      align-items: center;

      &:hover {
        background-color: #f1f1f1;

        span {
          opacity: 1;
          visibility: visible;
        }
      }

      span {
        position: absolute;
        top: 2px;
        right: -30px;
        display: flex;
        gap: 4px;
        transform: translate(10px);
        opacity: 0;
        visibility: hidden;
        transition: 0.3s ease-in-out;
      }

      span i {
        cursor: pointer;
      }
    }

    .listing_item {
      li button {
        padding: 4px;
        border-radius: 4px;
        background-color: #f1f1f1;
        color: black;
        box-shadow: 10px 5px 20px rgba(0, 0, 0, 0.03);
        width: 100%;
        display: flex;
        align-items: center;
        font-weight: 500;

        &.active {
          background-color: #ff8fb0;
          color: #fff;
        }
      }
    }
  }

  ul li img {
    width: 2.5rem;
    aspect-ratio: 1;
    border-radius: 3rem;
    object-fit: cover;
    margin-right: 0.5rem;

    @media (max-width: 576px) {
      width: 2rem;
      border-radius: 2rem;
      margin-right: 0.5rem;
    }
  }
}

li.liHead {
  >a {
    i {
      color: deeppink;
      font-size: 1.7rem;
      margin-right: 1rem;

      @media (max-width: 576px) {
        font-size: 1.2rem;
      }
    }

    span {
      font-size: 1.5rem;
      color: #000;

      @media (max-width: 768px) {
        font-size: 1.2rem;
      }
    }
  }
}

.layoutWrap {
  // display: flex;

  .sideWrap {
    width: 260px;
    position: fixed;
    left: 0;
    overflow-x: hidden;
    top: 3rem;
    height: 100vh;
    transition: 0.3s ease;

    &.sideWrap-rspnsv {
      width: 0rem;
    }

    .sidebarWrapper {
      // margin-top: 10px;
      overflow-y: scroll;
      height: 100%;
      -ms-overflow-style: none;
      scrollbar-width: none;
      width: 100%;
      padding: 20px 10px;
    }
  }

  .fullWidhtWrap {
    // margin-left: 17rem;
    // width: calc(100% - 17rem);
    transition: 0.3s ease;

    position: relative;
    margin-left: 260px;
    min-height: calc(100vh - 131px);
    // background: #eceff1;
    border-radius: 8px;

    &.fullWidhtWrap-rspnsv {
      margin-left: 0rem;
      width: 100%;
    }
  }
}

.toggleMenu {
  color: #fff;
  padding: 1rem;

  svg {
    color: #fff !important;
  }
}

// --Breaddcrum--

.home {
  background-color: #ff739d;
  color: #fff;
  border-radius: 4px;
  margin-right: 10px;
  padding: 6px;
}

table.fc-scrollgrid {
  td {
    span.info {
      white-space: normal;
    }
  }
}

.table-responsive {

  &.DentistTabel {
    table {
      th {
        &:nth-child(2) {
          width: unset;
          min-width: unset;
        }
      }
    }
  }

  table {
    th {
      border-top: 1px solid #e2e5e8;
      white-space: nowrap;
      padding: 1rem 0.75rem;
      font-size: 16px;
      vertical-align: middle;

      &:nth-child(2) {
        width: 200px;
        min-width: 200px;
      }
    }

    td {
      border-top: 1px solid #e2e5e8;
      padding: 1rem 0.75rem;
      font-size: 15px;
      white-space: nowrap;

      .name {
        font-size: 15px;
      }

      .table-avatar {
        font-size: inherit;
        font-weight: 400;
        vertical-align: middle;
        white-space: nowrap;
        margin: 0 !important;
        padding: 0;
        display: flex;
        align-items: center;

        .avatar {
          position: relative;
          display: block;
          width: 2.5rem;
          height: 2.5rem;
          object-fit: cover;
          margin-right: 1rem;
        }

        a {
          color: #272b41 !important;
        }

        span {
          color: #888888;
          display: block;
          font-size: 12px;
          margin-top: 3px;
        }
      }
    }
  }
}

// .appointment-request---

table {
  tr {
    td {
      div {
        img {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
}

.card-footer {
  .page-number {
    li {
      padding: 8px 12px;
    }
  }
}

.SideNav {
  height: 100%;

  .sidebar-header {
    padding: 15px;
  }

  .bottom-list {
    padding: 10px 0px;

    .open-list {
      li {
        a {
          display: block;
          margin-bottom: 10px;
          color: #000;
          font-size: 17px;
          padding: 10px 15px;
          transition: 0.2s ease;

          .counter {
            background-color: #000;
            border-radius: 50%;
            color: #000;
            display: inline-block;
            height: 25px;
            width: 25px;
            text-align: center;
            line-height: 25px;
            margin-left: 50px;
            background-color: #fff;
          }

          &.active {
            background-color: #212529;
            color: #fff;
          }

          &:hover {
            background-color: #212529;
            color: #fff;
          }
        }
      }
    }
  }
}

// --performance-

.perfTabsWrap {
  .tabHeader {
    display: flex;
    justify-content: center;

    button.Mui-selected {
      color: #ff6bb5;
    }

    span.MuiTabs-indicator {
      background-color: #ff6bb5;
    }
  }
}

.reviews {
  .reviewBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    border-radius: 9px;
    background: #e9e9e9;
    margin-bottom: 1rem;

    @media (min-width: 576px) {
      flex-direction: row;
    }

    .rating {
      position: relative;

      .starIC {
        width: 5rem;
        height: 5rem;
        color: #1a8700;
      }

      span {
        position: absolute;
        top: 52%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
      }
    }

    .reviewText {
      p {
        font-size: 1rem;
        line-height: 1.7rem;
        font-style: italic;
        font-weight: 700;
        padding-bottom: 1rem;
        border-bottom: 1px solid #d1d1d1;

        @media (min-width: 576px) {
          font-size: 1.2rem;
        }
      }
    }
  }

  .reviewBy {
    display: flex;
    align-items: center;
    gap: 1rem;

    img {
      width: 3.4rem;
      height: 3.4rem;
      border-radius: 50px;
    }

    .txtInfo {
      h5 {
        margin-bottom: 0;
      }

      span {
        color: #7b7b7b;
        font-size: 0.8rem;
      }
    }
  }
}

.reviewOverView {
  margin-bottom: 3rem;

  .overAll {
    h5>span {
      font-size: 0.8rem;
      color: #7a7a7a;
      margin-left: 10px;
    }

    .starGroup {
      svg {
        color: #1a8700;
      }

      span {
        font-size: 2rem;
        font-weight: 900;
        margin-right: 1rem;
        margin: 0.7rem 1rem 0.8rem 0;
      }
    }
  }
}

.starWrap {
  .star {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-bottom: 1.5rem;

    span.title {
      width: 5rem;
    }
  }
}

.breadcrum {
  span.arrow {
    background: #ff8fb0;
    padding: 4px;
    border-radius: 6px;
    margin-right: 10px;
    color: #fff;
  }
}

// --Image-upload-css--

.imgUploaderContainer {
  position: relative;

  .dropzone {
    border: 2px dashed #ccc;
    padding: 10px;
    text-align: center;

  }

  .dropzone p {
    margin: 0;
  }

  .image-preview {
    display: flex;
    gap: 2px;
    margin-top: 2rem;
    width: 100%;
  }

  .image-list {
    display: flex;
    gap: 20px 20px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .image-item {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    width: 100%;
    max-width: 200px;
    height: 180px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

  }



  button {
    display: inline-block;
    margin-bottom: 5px;
    padding: 2px;
    font-size: 16px;
    outline: none;
    border: none;
    text-align: center;
    width: 40px;
    margin: auto;
    background-color: #000;
    text-align: center;
    color: #fff;
    border-radius: 4px;


  }
}

@media (max-width: 991px) {
  .container {
    max-width: 100% !important;
  }
}

.validation_message {
  color: red;
  font-size: small;
  padding: 2px 1px 1px 1px;
}

.aboutIc {
  width: 40px;
  height: 40px;
  object-fit: contain;
  position: relative;
  z-index: 2;
}

.cardIcon {
  width: 30px;
  height: 30px;
  object-fit: contain;
  position: relative;
  z-index: 2;
}

.whychoose__box:hover .aboutIc {
  filter: invert(1);
}

// #navabrOnscroll {
//   display: none;
// }

.settings-cards-wrap {
  display: flex;
  gap: 20px;
  margin-top: 30px;
  width: 100%;
  justify-content: center;

  @media (max-width: 576px) {
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }

  .settings-cards {
    padding: 10px;
    text-align: center;
    border: 1px solid var(--color-pink);
    border-radius: 5px;
    width: 160px;

    i {
      color: #ff1493;
    }

    p {
      margin-bottom: 0;
      margin-top: 6px;
      font-weight: 600;
    }
  }
}

.notifications-switch {
  width: 100%;
  margin-top: 30px;
  padding-left: 25px;

  .notifi-label {
    margin-right: 20px;
  }
}

.selectBranch-edit {
  margin-top: 30px;
  // text-align: left;
  width: 100%;

  h5 {
    text-align: center;
  }

  .branchimg {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;

    @media (max-width: 576px) {
      display: block;
    }
  }

  .branch-items-wrap {
    max-height: 200px;
    overflow-y: auto;
  }

  a {
    color: var(--color-black);
  }

  .branch-item {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border: 1px solid transparent;
    transition: all 0.4s;

    &:hover {
      border-color: var(--color-pink);
    }

    p {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 18px;
    }
  }
}

// .active:active {
//   background-color: #ff8fc7;
// }
.backGround {
  background-color: #ff8fb0;
  border: 1px solid #ff6bb5;
  color: #ffffff;
}

.form-error {
  color: red;
}

.custm-style {
  padding: 10px;
}

.feed img {
  width: 250px;
  height: 250px;
  object-fit: contain;
}

.chat-footer img {
  object-fit: contain;
  height: 100px;
  width: 100%;
}

.chat-footer button {
  position: absolute;
}


// .attachment-image .input-group {
//   display: block !important;
// }

.attachment-image {
  align-items: end !important;
}

// .attachment-image img {
//   object-fit: cover;
// }

.attachment-image button.btn.msg-send-btn {
  width: 40px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.attachment-image {
  justify-content: space-between;
}

// .feed span {
//   display: none;
// }

// .box-0 {
//   display: none;
// }

.d-none {
  display: none;
}

// 25 Jan
.change-password form>div {
  position: relative;
}

.change-password form>div .showPasswrdIcn {
  position: absolute;
  top: 2.5rem;
  right: 0.8rem;
}

.howWorksLeft {
  padding: 1.5rem;
  position: relative;
  width: fit-content;

  &::before {
    content: "";
    border: 2px solid var(--color-pink);
    border-top-left-radius: 55%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 79%;
    height: 90%;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 40%;
    height: 40%;
    background-color: grey;
  }
}

.howWorksImg {
  max-width: 100%;
  width: 350px;
  height: 370px;
  object-fit: cover;
  // background-color: #fde6ed;
  // animation: showanswer 0.5s linear 0s normal;

  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 1);
  // border-radius: 26px;
  border-top-left-radius: 48%;
  box-shadow: inset 15px 6px 30px 6px #fde6ed,
    inset 0px 11px 28px 0px rgb(255, 255, 255);
}

.howWorksRtWrap {
  height: 100%;

  @media(min-width: 576px) {
    height: 260px;
  }

  #parentDiv {

    height: 300px;
    overflow-y: scroll;

    @media(min-width: 576px) {
      height: 340px;
      overflow-y: scroll;
    }
  }

  .howWorksItem {
    display: flex;
    gap: 1rem;
    padding-bottom: 1.5rem;
    position: relative;

    .count {
      width: 30px;
      height: 30px;
      font-size: 16px;
      border-radius: 50%;
      background: #ccc;
      // background: rgb(255, 143, 176);
      // background: linear-gradient(180deg, rgba(255, 143, 176, 1) 41%, rgba(204, 204, 204, 1) 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 30px;

      &::before {
        content: "";
        position: absolute;
        width: 2px;
        height: 100%;
        background: #ccc;
        // background: rgb(255, 143, 176);
        // background: linear-gradient(180deg, rgba(255, 143, 176, 1) 41%, rgba(204, 204, 204, 1) 100%);
        left: 15px;
        top: 0;
        z-index: -1;
      }

      &.filledCount {
        background: rgb(255, 143, 176);
        background: linear-gradient(180deg,
            rgba(255, 143, 176, 1) 41%,
            rgba(204, 204, 204, 1) 100%);
        animation: showanswer 0.5s linear 0s normal;
        color: white;
        cursor: pointer;

        &::before {
          background: rgb(255, 143, 176);
          background: linear-gradient(180deg,
              rgba(255, 143, 176, 1) 41%,
              rgba(204, 204, 204, 1) 100%);
        }
      }
    }

    .ansrWrap {
      padding: 1rem;
      background: #ff8fb057;
      border-radius: 1rem;
      border-top-left-radius: 0;
      animation: showanswer 0.5s linear 0s normal;

      p {
        margin: 0;
        font-weight: 600;
        font-size: 15px;
        color: #373737;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@keyframes showanswer {
  from {
    // height: 0px;
    // overflow: hidden;
    opacity: 0;
  }

  to {
    opacity: 1;
    // height: fit-content;
    // overflow: unset;
  }
}

.empty-chat {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  img {
    width: 300px;
    height: 300px;
    object-fit: contain;
    margin-bottom: 40px;
  }
}

.blue-dot {
  width: 10px;
  height: 10px;
  object-fit: cover;
  background-color: blue;
  border-radius: 50%;
}

.socialLoginWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-data {
  .btn {
    &.btn-active {
      background-color: var(--color-pink);
      color: #fff;
      font-weight: 500;
    }
  }
}

.navbar-toggler i {
  color: #fff;
  font-size: 24px;
}

.navbar-toggler span {
  color: #000;
  font-size: 24px;
}

.filter-data {
  display: flex;
  align-items: start;
  row-gap: 10px;

  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 10px;
}

.modal-container .modal-content {
  background-color: #fff !important;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
}

.modal-container .modal-content.edit_hoursmodal {
  padding: 30px 40px;
}

.slick-next:before,
.slick-prev:before {
  color: #ff8fb0 !important;
  font-size: 24px !important;
}

.cross_mdl {
  position: absolute;
  background-color: var(--color-pink);
  padding: 10px;
  top: 10px;
  right: 10px;
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: grid;
  cursor: pointer;
  place-content: center;
  z-index: 999;
}

.cross_mdl i {
  color: #fff;
  font-size: 16px;
}

.staff_ad {
  .uploadButton {
    display: inline-block;
    padding: 5px 10px;
    background-color: #ff8fb0;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }

  .uploadButton input[type="file"] {
    display: none;
  }
}

.cropImg {
  height: 350px;
  min-height: 350px;
  // object-fit: cover;
  width: 400px;
  max-width: 400px;
}

.cropp_image .uploadImage {
  display: inline-block;
  padding: 6px 10px;
  background-color: var(--color-pink);
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
}

.crop-img-full {
  width: 100%;
  height: 100%;
}

#loom-companion-mv3 {
  display: none !important;
}

.dentist_listing>li a {
  color: #000;
}

.profile-pic-container {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ff8fc7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}

.profile-pic-letter {
  color: #fff;
}

.form-check-input:checked {
  background-color: #ff6bb5 !important;
  border-color: #ff6bb5 !important;
}

.time-slot {
  height: 300px;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-snap-type: y;
}

.schedule-widget .tab-content .tab-pane {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-snap-type: y;
  max-height: 180px;
}

.time-slot-list-cntner {
  padding: 0px 4px;
  flex: 0 0 50%;
}

.schedule-widget .tab-content .tab-pane .card-title {
  position: sticky;
  top: 0;
  height: auto;
}

.ant-picker-panel-container {
  .ant-picker-presets {
    min-height: 25rem !important;
  }

  @media (max-width: 800px) {
    overflow: scroll !important;
    height: 400px;

    .ant-picker-panel-layout {
      flex-direction: column !important;

      .ant-picker-presets {
        max-width: 100% !important;
        min-height: 10rem !important;
      }

      .ant-picker-panels,
      .ant-picker-datetime-panel {
        flex-direction: column !important;
      }
    }
  }
}

@media (max-width:576px) {

  .support_container {
    position: relative;
    top: 0;

    &::before {
      content: " ";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      // background: -webkit-radial-gradient(top center, ellipse cover, rgba(255, 255, 255, 0.2) 0%, #00000087 100%);
      background-color: rgba(0, 0, 0, .4);
      z-index: 5;
    }

    .support__left-content {

      h4,
      h1 {
        color: #fff;
        z-index: 5;
        position: relative;

        @media(min-width:576px) {
          color: #000;
        }

      }
    }

    p.support__description {
      font-weight: 500;
      color: #fff;
      z-index: 5;
      position: relative;

      @media(min-width:576px) {
        color: #000;
      }
    }

  }

  .MuiDialog-paper {
    max-width: 550px !important;
    height: auto !important;
    width: fit-content !important;
  }

  .MuiDialog-paper h4.MuiTypography-root {
    font-size: 18px;
    padding: 0px;
  }

  .css-ypiqx9-MuiDialogContent-root {
    padding: 15px 15px !important;
  }

}


.contact_us-Form .contact-1 a.text-primary {
  color: #ff8fb0 !important;
}

.cursor-pointer {
  cursor: pointer
}

.listing-text {
  text-align: left;
}


.image-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2px;

  @media(max-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }

  @media(max-width: 480px) {
    grid-template-columns: 1fr;
  }

}

.single-image {

  height: 160px;
  display: flex !important;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;

  &.imageWrapper {

    @media(max-width: 576px) {
      height: auto;
      width: 100%;
    }
  }


}

.single-image img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}


.table-responsive::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.table-responsive::-webkit-scrollbar-track {
  background: none;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #cacaca;
  border: solid #cacaca;
  border-width: 0 6px;
  border-radius: 8px;
}

.navbar-brand {

  img {
    width: 200px;
    height: 40px;

    @media(min-width: 576px) {
      width: 220px;
      height: 40px;
    }
  }

}