// --Dentist---Finder--Responsive--Start--

@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {

  #myDIV {
    display: none;
    width: 0rem;
  }

  .layoutWrap .fullWidhtWrap {
    margin-left: 0;
    width: 100%;
    transition: 0.3s ease;
  }

  .hero__search {
    display: block !important;
    width: 100%;
  }

  .navbar-nav li {
    margin: auto;
  }

  .navbar-collapse .theme_btn {
    display: block;
    max-width: 200px;
    margin: auto;
  }
}

@media (max-width: 767.98px) {
  .sidebarWrapper ul li img {
    display: block;
  }
  .hero__search-box {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .find-dentist-container .patient_form button.btn-two {
    width: 100% !important;
    height: 60px !important;
  }
  .modal-container .modal-content.edit_hoursmodal {
    padding: 40px 20px;
  }
  .modal-container .modal-content {
    max-width: 400px;
  }
}

@media (max-width: 576px) {
  section.section-pdding {
    padding: 0rem 0rem 2rem;
  }
}


.mobile-padding{
  padding-left: 20px; 
  padding-right: 20px;

  @media (min-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
}